import React from "react"
import reviewStyle from "./review.module.css"
import { StaticQuery, graphql } from 'gatsby'
import PropTypes from "prop-types"
import Masonry from 'react-masonry-component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class TwitterWidget extends React.Component {
    constructor(props){
      super(props)
      this.state = {
        twetterCount: 4
      }
      this.handleClick = this.handleClick.bind(this)
    }

    handleClick(){
      this.setState({
        twetterCount: this.state.twetterCount + 6
      })
    }

    render(){
      const {data} = this.props;
      let tweets = data.allTwitterStatusesLookupTweets.edges.slice(0, this.state.twetterCount);

      return (
        <>
        <section id="review" className={reviewStyle.review}>
          <Masonry className={reviewStyle.twitterList}>
            {tweets.map(({ node }) => (
                <div className={reviewStyle.tweet} key={node.id}>
                    <div className={reviewStyle.twitterHeader}>
                      <img src={node.user.profile_image_url_https} alt={`${node.user.name}`} className={reviewStyle.profileIcon} text="profile" key={node.id}/>
                      <div className={reviewStyle.proDatails}>
                        <a href={`https://twitter.com/${node.user.screen_name}`} className={reviewStyle.twitterName} target="_blank" rel="noopener noreferrer">{node.user.name}</a>
                        <div className={reviewStyle.screenName}>
                          <a href={`https://twitter.com/${node.user.screen_name}`} className={reviewStyle.bold} target="_blank" rel="noopener noreferrer">@{node.user.screen_name}</a>
                        </div>
                      </div>
                      <a href={`https://twitter.com/${node.user.screen_name}/status/${node.id_str}`} className={reviewStyle.clear} target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon alt="ticon" icon={[`fab`, `twitter`]} size="xs" className={reviewStyle.twitterIcon} key={node.id} />  
                      </a>
                    </div>
                    <div className={reviewStyle.twitterMain}>
                        <p className={reviewStyle.twitterText}>
                        {(() =>{
                          switch (node.id){
                            case '809fb83a6113c2c3dae3b7b7d2f365bb': return node.full_text.replace('https://t.co/JjgjPTOH2v #Amazon', '').split(/\r\n|\n|\r/).reduce((texts, i) => [...texts, i, <br />], []).slice(0, -1);
                            case 'b8ba3ca34a14e64dfb74f8ca76c455dc': return node.full_text.replace('#読書 #デザイン経営戦略 #小山田育 #渡邊デルーカ瞳', '').split('https')[0].split(/\r\n|\n|\r/).reduce((texts, i) => [...texts, i, <br />], []).slice(0, -1);
                            case 'a4d2a8f046ea8229cc33528c5118803b' : return node.full_text.replace('\n#小山田育　#渡邊デルーカ瞳', '').split('https')[0].split(/\r\n|\n|\r/).reduce((texts, i) => [...texts, i, <br />], []).slice(0, -1);
                            default: return node.full_text.split('https')[0].split(/\r\n|\n|\r/).reduce((texts, i) => [...texts, i, <br />], []).slice(0, -1);
                          }
                        })()}
                        { (node.entities.urls != null && node.entities.urls.length > 0) ?
                            node.entities.urls.map(({ display_url, expanded_url }) => (
                            <a href={expanded_url} className={reviewStyle.tweetLink} key={`${node.id}-link`} rel="nofollow noreferrer">{ display_url } </a>
                        )) : '' }
                        { (node.entities.hashtags != null && node.entities.hashtags.length > 0) ?
                            node.entities.hashtags.map(({ text }) => (
                            <a href={`https://twitter.com/hashtag/${text}`} key={`${node.id}-${text}`} className={reviewStyle.hashtag} rel="nofollow noreferrer">#{text} </a>)
                        ) : '' }
                        </p>
                        { (node.entities.media !=null && node.entities.media.length > 0) ?
                            node.entities.media.map(({ media_url_https }) => (
                                <img src={media_url_https} alt="tweet" text="tweet" className={reviewStyle.tweetPic} key={node.id}/>
                        )) : '' }
                    </div>
                    <div className={reviewStyle.footer}>
                        <div className={reviewStyle.twitterText}><FontAwesomeIcon icon={[`fas`, `retweet`]} size="xs" className={reviewStyle.tweetFont}/><span className={reviewStyle.footerText}>{node.retweet_count}</span></div>
                        <div className={reviewStyle.twitterText}><FontAwesomeIcon icon={[`far`, `heart`]} size="xs" className={reviewStyle.tweetFont}/><span className={reviewStyle.footerText}>{node.favorite_count}</span></div>
                        <a href={`https://twitter.com/${node.user.screen_name}/status/${node.id_str}`} className={reviewStyle.twitterDate} target="_blank" rel="noopener noreferrer">{node.created_at.split(` `).splice(5).join(``)}. {node.created_at.split(` `).splice(1, 2).join(`. `)}</a>
                    </div>
                </div>
            ))}
            </Masonry>
            <div className={reviewStyle.buttonWrapper}>
              {this.state.twetterCount === 16 ? '' : <button onClick={this.handleClick} className={reviewStyle.loadButton}>もっと読む</button>}
            </div>
        </section>
        </>
      )
    }
}

TwitterWidget.propTypes = {
    data: PropTypes.shape({
      allTwitterStatusesLookupTweets: PropTypes.shape({
            full_text: PropTypes.string,
            favorite_count: PropTypes.number,
            retweet_count: PropTypes.number,
            created_at: PropTypes.string,
            id: PropTypes.string,
            id_str: PropTypes.string,
            entities: PropTypes.shape({
              urls: PropTypes.arrayOf(
                PropTypes.shape({
                  display_url: PropTypes.string,
                  expanded_url: PropTypes.string,
                }),
              ),
              media: PropTypes.arrayOf(
                PropTypes.shape({
                  media_url_https: PropTypes.string,
                }),
              ),
              hashtags: PropTypes.arrayOf(
                PropTypes.shape({
                  text: PropTypes.string,
                }),
              ),
            }),
            user: PropTypes.shape({
                name: PropTypes.string,
                screen_name: PropTypes.string,
                url: PropTypes.string,
                profile_image_url_https: PropTypes.string
            }),
        }).isRequired
    }),
}

const Review = props => (
    <StaticQuery
      query={graphql`
        query Review {
          allTwitterStatusesLookupTweets {
            edges {
              node {
                full_text
                favorite_count
                retweet_count
                created_at
                id
                id_str
                entities {
                  urls {
                    display_url
                    expanded_url
                  }
                  media {
                    media_url_https
                  }
                  hashtags {
                    text
                  }
                }
                user {
                  name
                  screen_name
                  url
                  profile_image_url_https
                }
              }
            }
          }
        }`
      }
      render={data => <TwitterWidget data={data} {...props} />}
    />
)

export default Review
