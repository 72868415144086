import React, { Component } from "react"
import infoStyle from "../components/info.module.css"

class Info extends Component {
  render() {
    return (
      <section id="contact" className={infoStyle.mgrBtm}>
        <h2 className={infoStyle.centerNext}>お問い合わせ</h2>
        <div className={infoStyle.float}>
          <address className={infoStyle.box}>
            HI(NY) ニューヨークオフィス
            <br />
            +1 646 808 0708
            <br />
            <a href="mailto:info@hinydesign.com" className={infoStyle.mail}>
              info@hinydesign.com
            </a>
          </address>
          <address className={infoStyle.box}>
            HI(NY) 京都オフィス
            <br />
            +81 090 7964 4272
            <br />
            <a href="mailto:info@hinydesign.com" className={infoStyle.mail}>
              info@hinydesign.com
            </a>
          </address>
        </div>
        <div className={infoStyle.linkWrapper}>
          <a
            href="http://hinydesign.com/"
            target="_blank"
            rel="noopener noreferrer"
            className={infoStyle.mgrTop}
          >
            hinydesign.com
          </a>
          <br />
          <a
            href="https://www.instagram.com/hinydesign/"
            target="_blank"
            rel="noopener noreferrer"
            className={infoStyle.mgrTop}
          >
            Instagram
          </a>
        </div>
      </section>
    )
  }
}

export default Info
