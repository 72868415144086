import React from "react"
import coverStyle from "./cover.module.css"
import Logo from '../../static/logo.svg'
import { Link } from "gatsby";

class Cover extends React.Component {
    render(){
        return (
            <>
            <section id="top">
              <div>
                <Link to="/"><img src={Logo} alt="Logo" className={coverStyle.logo} align="right"/></Link><br/>
                <h2 className={coverStyle.englishTitle}>
                  <span>New York</span><br/>
                  <span>Design Strategies</span><br/>
                  <span>for Japanese Business:</span><br/>
                  <span>Winning in the Global Market</span>
                </h2>
              </div>
              <h1>
                <span>ニューヨークの</span><br/>
                <span>アートディレクターがいま、</span><br/>
                <span>日本のビジネスリーダーに</span><br/>
                <span>伝えたいこと</span>
              </h1>
              <h3>
                  世界に通用するデザイン経営戦略
              </h3>
              <p className={coverStyle.authorsName}>
                  小山田 育 ｜ 渡邊 デルーカ 瞳
              </p>
              <Link to="/stores" className="external">オンラインで購入</Link>
            </section>
            </>
        )
    }
}

export default Cover