import React, {Component} from "react"
import profileStyle from "./profile.module.css"
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'


class ProfilePage extends Component {
    render(){
        const {data} = this.props;
        return (
            <>
            <section id="profile" className={profileStyle.profile}>
                <Img fluid={data.file.childImageSharp.fluid} alt="portrait" title="Author's portrait" className={profileStyle.portrait} />
                <br/>
                <p className={profileStyle.names}>
                    <span className={profileStyle.mgrRight}>小山田 育 Iku Oyamada</span>渡邊 デルーカ 瞳 Hitomi Watanabe-Deluca
                </p>
                <p>
                    HI(NY) 共同代表<br/>
                    クリエイティブ・ディレクター / アートディレクター / グラフィックデザイナー<br/>
                    NYの美術大学 School of Visual Arts、グラフィックデザイン科を卒業。MTV、ブランディング・エージェンシーのThe Seventh Art を経て2008年、HI(NY)を設立。近年の主な仕事に、米国コカコーラの新商品ブランディング＆パッケージデザイン、国連の展覧会デザインなど。アトランタのHigh Museumやパリのコレットにて展覧会に参加。One Show, Graphis, GD USAなど多数受賞。AIGA会員、NYアートディレクターズクラブ会員。
                </p>
                <a href="http://hinydesign.com/" target="_blank" rel="noopener noreferrer" className={profileStyle.mgrTop}>hinydesign.com</a><br/>
                <a href="https://www.hitomiwatanabe.com/" target="_blank" rel="noopener noreferrer" className={profileStyle.mgrTop}>hitomiwatanabe.com</a>
            </section>
            </>
        )
    }
}

const Profile = props => (
  <StaticQuery
    query={graphql`
      query Profile {
        file(relativePath: {eq: "Portrait.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <ProfilePage data={data}{...props} />}
    />
)

export default Profile