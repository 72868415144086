import React, { Component } from 'react'
import introStyle from "./intro.module.css"
import Image1 from '../../static/1.svg'
import Image2 from '../../static/2.svg'
import Image3 from '../../static/3.svg'
import Image4 from '../../static/4.svg'
import Image5 from '../../static/5.svg'
import Image7 from '../../static/7.svg'
import Image8 from '../../static/8.svg'

class Intro extends Component {

    render(){
        return (
          <>
            <section id="intro">
                <h1 data-sal='slide-up' data-sal-duration="1800" 
                data-sal-easing="ease-in-out" className={introStyle.firstSentence}>
                    ブランディングとは？
                </h1>
                <div className={introStyle.firstSentence} data-sal='slide-up' data-sal-delay="500" data-sal-duration="1800" 
                data-sal-easing="ease-in-out">
                  <img src={Image7} className={introStyle.brandingBig} alt="ブランディング、広告、PRの違い" title="ブランディングとは？"/>
                  <img src={Image8} className={introStyle.brandingSmall} alt="ブランディング、広告、PRの違い" title="ブランディングとは？"/>
                </div>
                <p data-sal='slide-up' data-sal-delay="500" data-sal-duration="1800" 
                data-sal-easing="ease-in-out" className={introStyle.nomal}>
                    ブランディングとは、<br/>
                    <br/>
                    ● 戦略的に企業、商品やサービスの強みを引き出し<br/>
                    ● 環境や時代、消費者のニーズを踏まえながら<br/>
                    ● 消費者や社会に伝わるようなかたちで表現し<br/>
                    ● 企業のブランド価値を向上させる<br/>
                    <br/>
                    経営戦略です。<br/><br/><br/>
                    そしてブランディングの最終ゴールは、<br/>
                    顧客ロイヤリティの獲得。<br/>
                    すなわち、ファンになってもらうことです。<br/>
                </p>
                <p data-sal='slide-up' data-sal-delay="500" data-sal-duration="1800" 
                data-sal-easing="ease-in-out" className={introStyle.firstSentence}>
                    重要なのは、<br/>
                    「売る」こと以上に<br/>
                    ファンになってもらうこと
                </p>
                <div className={introStyle.center}>
                  <img src={Image1} alt="ファンになってもらう" title="重要なのはファンになってもらうこと"/>
                  <h2 className={introStyle.japanese}>ビジネスにクリエイティブな視点が必要な理由</h2>
                  <p className={introStyle.left}>
                    ビジネスの目指すゴールは、商品やサービスを購入してもらうこと。そしてリピートして買ってくれるロイヤルカスタマーを掴むこと。ファンになってもらうことです。<br/>
                    商品やサービスの質が良い＝実質的価値が高い、ことは前提条件として、この商品やサービスの情緒的価値を「伝えて、理解してもらい、信頼してもらい、共感してもらい、ファンになってもらう」上で最も大切なことは何でしょうか？<br/>
                    それはビジネスから発せられる全てのメッセージが矛盾なく同じ方向を目指していて、世界観がぶれることなく一貫していることです。<br/>
                  </p>
                </div>
                <div className={introStyle.centerNext}>
                  <h2>
                    Without Branding<br/>
                    <span className={introStyle.small}>ブランディングをしなかった場合</span>
                  </h2>
                  <img src={Image2} alt="without branding" title="ブランディングをしなかった場合"/>
                  <p className={introStyle.small}>
                    それぞれのものが様々な方向に向かって一貫性なくつくられ、
                    サービス・企業として何を目指しているのか分からない
                  </p>
                </div>
                <div className={introStyle.centerNext}>
                  <h2>
                    With Branding<br/>
                    <span className={introStyle.small}>ブランディングをした場合</span>
                  </h2>
                  <img src={Image3} alt="with branding" title="ブランディングをした場合"/>
                  <p className={introStyle.small}>
                    すべてのものが北極星（＝ブランド・システム）に向かって一貫性をもってつくられ、
                    サービス・企業として目指しているものが明確に伝わる
                  </p>
                </div>
                <div className={introStyle.centerNext}>
                  <img src={Image4} alt="直感的に感じてもらう" title="世界観を直感的に感じてもらうために"/>
                  <h2 className={introStyle.japanese}>世界観を直感的に感じてもらうために</h2>
                  <p className={introStyle.left}>
                    ブランドの本質である、強みや「らしさ」を、消費者に伝わるような特徴として、多角的にブランドを表現し、消費者が「このブランドはこういうブランドなのだな」とその世界観を直感的に感じることができるようにするのがブランディングです。<br/>
                    <br/>
                    企業・商品・サービスの強みを引き出し、本質を見極め、その本質をわかりやすく概念化し、その概念をバーバル（言語）とビジュアル（視覚）によって魅力的に表現して、消費者が直感的に感じるかたちに体現することがブランディングのプロセスです。<br/>
                  </p>
                </div>
                <div className={introStyle.centerNext}>
                  <img src={Image5} alt="brand system" title="HI(NY)のブランディング"/>
                  <h2 className={introStyle.japanese}>HI(NY)のブランディング</h2>
                  <p className={introStyle.left}>
                    本書では、実際にブランディングはどのようにおこなっていくのか、順を追って説明します。各ステップに事例と読者のみなさんが実践できるようExerciseもありますので、一緒に考えてみてください。
                  </p>
                </div>
            </section>
          </>
        )
    }

}

export default Intro