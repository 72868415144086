import React, { Component } from 'react'
import prolodueStyle from "./prologue.module.css"

class Prologue extends Component {
    constructor(props) {
        super(props);
        this.state = {isOpen: false};
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick = () => {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen
        }));
    }

    render(){
        const extra = (
            <div>
                <p>
                  ブランディングとは経営戦略です。そして、ブランディングの最終ゴールは、顧客ロイヤリティの獲得。すなわちファンになってもらうことです。世界の経営者たちは、ビジネスを成功させるにはブランディングが必要不可欠な経営戦略であると認識しています。そのため、事業を立ち上げるとまずブランドの構築＝ブランディングをはじめます。そして、経営陣や専門家とともに、ブランディングの要「クリエイティブ」を統括するアートディレクターを事業戦略構築のはじめから起用し、ブランディングを進めていきます。
                </p>
                <p>
                  この本では、ブランディング先進国であるアメリカ、ニューヨークを拠点に、世界の様々な国のブランディングに20年近く携わってきたアートディレクターとしての視点から、日本のビジネスリーダーのみなさんに、ブランディングを理解し、ご自分のビジネスに活かしていただくことをゴールとしています。クリエイティブの視点から、マーケターの書いた難しいブランディング書籍より気軽に、そしてデザイナーの書いたものより実践的に読んでもらえるようにしました。
                </p>
                <p>
                  世界のビジネスはいま、優しい方向に向かっています。キーワードはConnection、繋がりです。ビジネスとは、働く人々がやりがいを感じ、楽しみながら自己実現をサポートする存在であるべきだと思います。そして、それにはブランディングが必要です。
                  この本をきっかけに、みなさんがブランディングを経営戦略として取り入れ、ビジネスに関わる全ての人たちとともに楽しみながら愛されるブランドを構築し、ビジネスを成功させることができれば、こんなに嬉しいことはありません。
                </p>
                <div className={prolodueStyle.wrap}>
                  <button onClick={this.handleClick} className={prolodueStyle.button}>閉じる</button>
                </div>
            </div>
        );

        const readMore = (
            <div className={prolodueStyle.wrap}>
              <button onClick={this.handleClick} className={prolodueStyle.button}>もっと読む</button>
            </div>
        )

        return(
            <>
            <section id="prologue" className={prolodueStyle.top}>
                <p>
                    みなさんにとって、ビジネスの成功とは何でしょうか?<br/>
                    私たちが目指す成功とは、会社のビジョンや強みを活かし、ビジネスに関わる人々がともにやりがいを感じながら収益を上げることです。そしてその結果、消費者や人々の暮らしも豊かになることです。<br/>
                    夢のような話に聞こえるかもしれません。しかし、この成功を可能にする経営戦略があります。<br/>
                    それはブランディングです。<br/>
                    ブランディングと聞くと、高級ブランドにのみ必要なもの、自分には関係のないもの、と考える方が多いかもしれません。また、よく聞くけれども実際には何をしたらよいのかわからない、という方もいるかもしれません。この本はそのような方にこそ読んでいただきたいと思いつくりました。
                </p>
                {this.state.isOpen ? extra : readMore}
            </section>
            </>
        );
    }
}

export default Prologue