import React, { Component } from "react"
import ReactModal from 'react-modal'
import Products from "../components/products.js"

import goodsStyle from "../components/goods.module.css"

class Goods extends Component {
  constructor () {
    super();
    this.state = {
      showModal: false
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleOpenModal () {
    this.setState({ showModal: true });
  }

  handleCloseModal () {
    this.setState({ showModal: false });
  }

  render() {
    return (
      <section id="goods">
        <h2 className={goodsStyle.centerNext}>関連商品</h2>
        <Products />
        <p>
          <small>※個人情報については、
            <button onClick={this.handleOpenModal} class={goodsStyle.privacy}>プライバシーポリシー</button>に基づき管理いたします。</small>
        </p>
        <ReactModal
          isOpen={this.state.showModal}
          contentLabel="onRequestClose privacy"
          onRequestClose={this.handleCloseModal}
          className={goodsStyle.modal}
          overlayClassName={goodsStyle.overlay}>
          <button onClick={this.handleCloseModal} class={goodsStyle.close}>×</button>
          <h2 className={goodsStyle.center}>プライバシーポリシー</h2>
          <h5 className={goodsStyle.h5}>法令の遵守</h5>
          <p>当社は個人情報を取り扱うにあたり、個人情報を保護することは、社会的責務であると考えております。次のとおり「個人情報保護方針」を定め、これを遵守してまいります。</p>
          <h5 className={goodsStyle.h5}>利用目的の明示及び目的外使用の禁止</h5>
          <p>当社では、下記の目的で個人情報をご提供いただいております。これ以外の目的でご提供いただく際は、事前にその旨をお伝えした上で実施いたします。本人の同意を得ずに個人情報を目的以外に利用することはいたしません。<br/>
            ・会員登録<br />
            ・ニュースレター等の送付<br />
            ・上記に纏わる各種ご連絡
          </p>
          <h5 className={goodsStyle.h5}>個人情報の第三者への非開示・非提供</h5>
          <p>提供いただいた個人情報は、下記の場合を除いて第三者に開示、提供いたしません。この場合であっても、開示・提供する情報は必要最小限度のものといたします。<br />
            ・ご本人の同意がある場合<br />
            ・法的根拠に基づき官公庁などから請求された場合
          </p>
        </ReactModal>
      </section>
    )
  }
}

export default Goods
