import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import productsStyle from "../components/products.module.css"

const Products = () => {
  const { allShopifyProduct } = useStaticQuery(
    graphql`
      query {
        allShopifyProduct {
          edges {
            node {
              id
              title
              handle
              createdAt
              images {
                id
                originalSrc
                localFile {
                  name
                }
              }
              variants {
                price
              }
              description
            }
          }
        }
      }
    `
  )

  return (
    <div>
      {allShopifyProduct.edges ? (
        allShopifyProduct.edges.map(
          ({
            node: {
              id,
              handle,
              title,
              images,
              variants: [firstVariant],
              description,
            },
          }) => (
            <div key={id}>
              <div className={productsStyle.swipe}>
                {images.map(image => (
                  <img
                    src={image.originalSrc}
                    key={image.id}
                    className={productsStyle.proImage}
                    alt={image.localFile.name}
                  />
                ))}
              </div>
              <p>
                <b>
                  {title}
                  <br />￥{firstVariant.price}
                </b>
              </p>
              <p>{description}</p>
              <a
                href="https://nybrandingbook.myshopify.com/cart/36790961766552:1?channel=buy_button"
                target="_blank"
                rel="noopener noreferrer"
              >
                今すぐ購入
              </a>
              <br />
              <br />
              <a
                href="https://shop.hinydesign.com/ja"
                target="_blank"
                rel="noopener noreferrer"
              >Tシャツやトートのオンラインストアはこちら</a>
              <br />
              <br />
              <p>私たちはこのストアの利益の100％を、社会的養護を経験した当事者の若者を応援している非営利団体に寄付しています。</p>
            </div>
          )
        )
      ) : (
        <p>Coming Soon</p>
      )}
    </div>
  )
}

export default Products
