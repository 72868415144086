import React from "react"
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import Container from "../components/container.js"
import SEO from "../components/SEO"
import Navbar from "../components/navbar.js"
import Cover from "../components/cover.js"
import Prologue from "../components/prologue.js"
import Intro from "../components/intro.js"
import Profile from "../components/profile.js"
import Review from "../components/review.js"
import News from "../components/news.js"
import Goods from "../components/goods.js"
import Info from "../components/info.js"

library.add(fab, fas, far)

export default () => (
  <Container>
    <SEO title="ブランディングの本 『ニューヨークのアートディレクターがいま、日本のビジネスリーダーに伝えたいこと』" />
    <Navbar />
    <div className="left">
      <Cover />
    </div>
    <div className="right">
      <Prologue />
      <Intro />
      <Profile />
      <Review />
      <News />
      <Goods />
      <Info />
    </div>
  </Container>
)
