import React, { Component } from "react"
import newsStyle from "./news.module.css"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

class NewsPage extends Component {
  render() {
    const { data } = this.props
    return (
      <>
        <section id="news" className={newsStyle.grid}>
          <div>
            <a
              href="https://www.osaka.cci.or.jp/event/seminar/202104/D11210618015.html"
              target="_blank"
              rel="noopener noreferrer"
              className={newsStyle.link}
            >
              <Img
                fluid={data.default.childImageSharp.fluid}
                alt="default pic"
                title="HI(NY) Picture"
                className={newsStyle.pic}
              />
              <p className={newsStyle.nomal}>
                大阪商工会議所主催「英文Webサイトアップデート講座 (6/18)」でIkuが講師をします。是非ご参加ください。
              </p>
              <p className={newsStyle.small}>2021.06.10</p>
            </a>
          </div>
          <div>
            <a
              href="https://piu-bizterrace.com/products/18seminar-201105?variant=33064061763638"
              target="_blank"
              rel="noopener noreferrer"
              className={newsStyle.link}
            >
              <Img
                fluid={data.default.childImageSharp.fluid}
                alt="default pic"
                title="HI(NY) Picture"
                className={newsStyle.pic}
              />
              <p className={newsStyle.nomal}>
                PIU BizTerrace主催「DtoCブランドにおけるブランディング×デザイン講座 (2/19)」でIkuが講演します。
              </p>
              <p className={newsStyle.small}>2021.02.18</p>
            </a>
          </div>
          <div>
            <a
              href="https://designscramblecast.jp/hitomiwatanabe"
              target="_blank"
              rel="noopener noreferrer"
              className={newsStyle.link}
            >
              <Img
                fluid={data.default.childImageSharp.fluid}
                alt="default pic"
                title="HI(NY) Picture"
                className={newsStyle.pic}
              />
              <p className={newsStyle.nomal}>
                Design ScrambleのポッドキャストにHitomiが出演しました。
              </p>
              <p className={newsStyle.small}>2021.01.20</p>
            </a>
          </div>
          {/* <div>
            <a
              href="https://note.com/dezaken/n/n26b7a9b8b85a"
              target="_blank"
              rel="noopener noreferrer"
              className={newsStyle.link}
            >
              <Img
                fluid={data.default.childImageSharp.fluid}
                alt="default pic"
                title="HI(NY) Picture"
                className={newsStyle.pic}
              />
              <p className={newsStyle.nomal}>
                12月5日（土）オンラインで開催される「デザイン×経営学勉強会」の「著者が加わる対話型読書会」にIkuが参加します。
              </p>
              <p className={newsStyle.small}>2020.11.09</p>
            </a>
          </div> */}
          {/* <div>
            <a
              href="https://www.advertimes.com/20201007/article325575/"
              target="_blank"
              rel="noopener noreferrer"
              className={newsStyle.link}
            >
              <Img
                fluid={data.default.childImageSharp.fluid}
                alt="default pic"
                title="HI(NY) Picture"
                className={newsStyle.pic}
              />
              <p className={newsStyle.nomal}>
                宣伝会議2020年11月号（本日10/1発売）にIkuが寄稿しています。『狭義の日本と広義の欧米
                「デザイン」の定義差によるブランドへの影響』是非ご一読下さい。
              </p>
              <p className={newsStyle.small}>2020.10.02</p>
            </a>
          </div> */}
          <div>
            <a
              href="https://www.hitomiwatanabe.com/ny-branding-book-website/"
              target="_blank"
              rel="noopener noreferrer"
              className={newsStyle.link}
            >
              <Img
                fluid={data.default.childImageSharp.fluid}
                alt="default pic"
                title="HI(NY) Picture"
                className={newsStyle.pic}
              />
              <p className={newsStyle.nomal}>
                ブランディング書籍のウェブサイトを公開しました。
              </p>
              <p className={newsStyle.small}>2020.05.14</p>
            </a>
          </div>
          {/* <div>
            <a
              href="https://store.tsite.jp/kyoto-okazaki/event/business/9088-1333270821.html"
              target="_blank"
              rel="noopener noreferrer"
              className={newsStyle.link}
            >
              <Img
                fluid={data.default.childImageSharp.fluid}
                alt="default pic"
                title="HI(NY) Picture"
                className={newsStyle.pic}
              />
              <p className={newsStyle.nomal}>
                9月8日(日)にIkuが京都TSUTAYAでトークイベントをします
              </p>
              <p className={newsStyle.small}>2019.08.29</p>
            </a>
          </div> */}
          <div>
            <a
              href="https://www.hitomiwatanabe.com/hiny-book-cover-design/"
              target="_blank"
              rel="noopener noreferrer"
              className={newsStyle.link}
            >
              <Img
                fluid={data.Book3.childImageSharp.fluid}
                alt="HI(NY) Book Cover Design"
                title="HI(NY) Book Cover Design"
                className={newsStyle.pic}
              />
              <p className={newsStyle.nomal}>
                表紙デザインができるまでのプロセス
              </p>
              <p className={newsStyle.small}>2019.06.09</p>
            </a>
          </div>
          {/* <div>
            <a
              href="https://www.facebook.com/events/314420642783793/"
              target="_blank"
              rel="noopener noreferrer"
              className={newsStyle.link}
            >
              <Img
                fluid={data.default.childImageSharp.fluid}
                alt="default pic"
                title="HI(NY) Picture"
                className={newsStyle.pic}
              />
              <p className={newsStyle.nomal}>
                6月22日(土)に大阪でトークイベントをします
              </p>
              <p className={newsStyle.small}>2019.06.06</p>
            </a>
          </div> */}
          <div>
            <a
              href="https://www.hitomiwatanabe.com/hiny-book-thank-you/"
              target="_blank"
              rel="noopener noreferrer"
              className={newsStyle.link}
            >
              <Img
                fluid={data.Book4.childImageSharp.fluid}
                alt="HI(NY) Book Thanks"
                title="HI(NY) Book Thank You"
                className={newsStyle.pic}
              />
              <p className={newsStyle.nomal}>
                出版トークイベントとパーティーを終えて
              </p>
              <p className={newsStyle.small}>2019.05.19</p>
            </a>
          </div>
          <div>
            <a
              href="https://www.hitomiwatanabe.com/the-publishing-process/"
              target="_blank"
              rel="noopener noreferrer"
              className={newsStyle.link}
            >
              <Img
                fluid={data.Book2.childImageSharp.fluid}
                alt="The publishing process"
                title="The publishing process"
                className={newsStyle.pic}
              />
              <p className={newsStyle.nomal}>出版するまでの道のり</p>
              <p className={newsStyle.small}>2019.05.05</p>
            </a>
          </div>
          <div>
            <a
              href="https://www.hitomiwatanabe.com/hiny-book/"
              target="_blank"
              rel="noopener noreferrer"
              className={newsStyle.link}
            >
              <Img
                fluid={data.Book1.childImageSharp.fluid}
                alt="HI(NY) Book"
                title="HI(NY) Book"
                className={newsStyle.pic}
              />
              <p className={newsStyle.nomal}>書籍出版とイベントのお知らせ</p>
              <p className={newsStyle.small}>2019.04.21</p>
            </a>
          </div>
        </section>
      </>
    )
  }
}

const News = props => (
  <StaticQuery
    query={graphql`
      query News {
        Book1: file(relativePath: { eq: "Book1.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 350) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        Book2: file(relativePath: { eq: "Book2.jpeg" }) {
          childImageSharp {
            fluid(maxWidth: 350) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        Book3: file(relativePath: { eq: "Book3.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 350) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        Book4: file(relativePath: { eq: "Book4.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 350) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        default: file(relativePath: { eq: "6.png" }) {
          childImageSharp {
            fluid(maxWidth: 350) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <NewsPage data={data} {...props} />}
  />
)

export default News
