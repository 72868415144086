import React from "react";
import { Link } from "gatsby";
import { Link as Scroll } from "react-scroll";
import navStyle from "./navbar.module.css";

class Navbar extends React.Component {
  constructor(props){
    super(props)
    this.state = { 
      showMenu: false,
      visible: false
    }
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  componentDidMount(){
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount(){
    window.removeEventListener("scroll", this.handleScroll)
  }

  handleScroll = () => {
    const currentScrollpos = window.pageYOffset
    const coverBottom = document.getElementById("top").clientHeight
    let navVisible = currentScrollpos > coverBottom;
    
    this.setState({
      visible: navVisible
    })
  }

  toggleMenu = () => {
    this.setState(prevState =>({
      showMenu: !prevState.showMenu
    }))
  }

  render(){
    const navActive = this.state.showMenu ? navStyle.isActive : ''
    const menuActive = this.state.showMenu ? navStyle.isActive : '';
    const amazonActive = this.state.showMenu ? navStyle.isActive : '';
    const leftNavVisible = this.state.visible ? navStyle.isVisible : '';

    return (
      <>
        <header>  
          <nav className={`${navStyle.navbar} ${navActive}`}>
            <div className={`${navStyle.leftNav} ${leftNavVisible}`}>
              <button className={`${navStyle.navToggle} ${navActive}`} onClick={this.toggleMenu} aria-label="left nav">
                <div className={navStyle.bar1}></div>
                <div className={navStyle.bar2}></div>
                <div className={navStyle.bar3}></div>
              </button>
              <Link to="/stores" className={`${navStyle.amazon} ${amazonActive}`}>オンラインで購入</Link>
            </div>
            <div className={`${navStyle.doubleNav} ${menuActive}`}>
              <div className={navStyle.langNav}>
                <Link to="/" className={navStyle.jp} onClick={this.toggleMenu}>日</Link>
                <Link to="/" className={navStyle.lang} onClick={this.toggleMenu}>中</Link>
                <Link to="/" className={navStyle.lang} onClick={this.toggleMenu}>EN</Link>
              </div>
              <div className={navStyle.mainNav}>
                <Scroll to="top" className={navStyle.nav} activeClass={navStyle.active} spy={true} smooth={true} offset={-80} onClick={this.toggleMenu}>トップ</Scroll>
                <Scroll to="prologue" className={navStyle.nav} activeClass={navStyle.active} spy={true} smooth={true} offset={-130} onClick={this.toggleMenu}>はじめに</Scroll>
                <Scroll to="intro" className={navStyle.nav} activeClass={navStyle.active} spy={true} smooth={true} offset={-220} onClick={this.toggleMenu}>本紹介</Scroll>
                <Scroll to="profile" className={navStyle.nav} activeClass={navStyle.active} spy={true} smooth={true} offset={-160} onClick={this.toggleMenu}>著者紹介</Scroll>
                <Scroll to="review" className={navStyle.nav} activeClass={navStyle.active} spy={true} smooth={true} offset={-160} onClick={this.toggleMenu}>レビュー</Scroll>
                <Scroll to="news" className={navStyle.nav} activeClass={navStyle.active} spy={true} smooth={true} offset={-160} onClick={this.toggleMenu}>お知らせ</Scroll>
                <Scroll to="goods" className={navStyle.nav} activeClass={navStyle.active} spy={true} smooth={true} offset={-200} onClick={this.toggleMenu}>関連商品</Scroll>
                <Scroll to="contact" className={navStyle.nav} activeClass={navStyle.active} spy={true} smooth={true} offset={-180} onClick={this.toggleMenu}>お問い合わせ</Scroll>
              </div>
            </div>
          </nav>
        </header>
      </>
    )
  }
}
  
export default Navbar